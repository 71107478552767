<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
  >
    <InvoiceForm
      :disabled="$store.getters.loading[`put:api/finances/invoices/${editedInvoice.id}`]"
      :errors="invoiceValidationErrors"
      :invoice="editedInvoice"
      @clear:errors="CLEAR_INVOICE_VALIDATION_ERRORS"
      @cancel="goBack"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import store from '@/store';
import InvoiceForm from '@/components/forms/InvoiceForm';

export default {
  name: 'EditInvoice',

  components: { InvoiceForm },

  computed: {
    ...mapState('invoices', ['editedInvoice', 'invoiceValidationErrors', 'invoiceFilterParams']),
  },

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('invoices/editInvoice', +to.params.invoiceId)
      .then(() => {
        next();
      })
      .catch(() => {
        next(to.meta.getBackRoute());
      });
  },

  methods: {
    ...mapActions('invoices', ['updateInvoice']),
    ...mapMutations('invoices', ['CLEAR_INVOICE_VALIDATION_ERRORS']),

    onSave(invoice) {
      this.updateInvoice(invoice).then(() => {
        this.goBack();
      });
    },

    goBack() {
      this.$router.push(this.$route.meta.getBackRoute());
    },
  },
};
</script>
